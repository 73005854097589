import React from "react";
import { Routes, Route } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";

import SideNavDrawer from "./components/navigation/SideNavDrawer";

const PalletReview = React.lazy(() => import("./pages/PalletReview"));
const AuditPallet = React.lazy(() =>
  import("./components/palletReview/AuditPallet")
);
const DockDoorStatus = React.lazy(() => import("./pages/DockDoorStatus"));
const DockDoorControl = React.lazy(() => import("./pages/DockDoorControl"));
const Dashboard = React.lazy(() => import("./pages/Dashboard"));
const PalletArchive = React.lazy(() => import("./pages/PalletArchive"));
const TensorInsights = React.lazy(() => import("./pages/TensorInsights"));
const OperationalPerformance = React.lazy(() => import("./pages/OperationalPerformance"));
const PickerDetail = React.lazy(() => import("./pages/PickerDetail"));

export default function AuthRoutes() {
  return (
    <React.Fragment>
      <SideNavDrawer>
        <React.Suspense fallback={<CircularProgress />}>
          <Routes>
            <Route path="/" exact element={<PalletReview />} />
            <Route
              path="/auditpallet/:inference_id/:dc_id"
              exact
              element={<AuditPallet />}
            />
            <Route path="/dockdoorstatus" exact element={<DockDoorStatus />} />
            <Route
              path="/dockdoorcontrol"
              exact
              element={<DockDoorControl />}
            />
            <Route path="/dashboard" exact element={<Dashboard />} />
            <Route path="/palletarchive" exact element={<PalletArchive />} />
            <Route path="/vor-gpt" exact element={<TensorInsights />} />
            <Route path="/operational-performance" exact element={<OperationalPerformance />} />
            <Route path="/picker-detail" exact element={<PickerDetail />} />
          </Routes>
        </React.Suspense>
      </SideNavDrawer>
    </React.Fragment>
  );
}
