export const SESSION_KEY = "QCBOT_LIST_SESSION";

export const getInferenceItemStatus = (data) => {
  if (data?.is_un_trained_sku) {
    if (data?.is_qc_bot_audited_sku) {
      return "AUDITED";
    } else {
      return "UNTRAINED";
    }
  }

  let status = data?.status;
  if (status !== "MATCHED") {
    if (data?.actual_quantity > data?.expected_quantity) status = "Extra";
    else status = "Missing";
  } else status = "No error";

  if (data?.is_qc_bot_audited_sku) status = "Audited";
  else if (data?.is_unverifiable_sku) status = "Can’t confirm";

  return status;
};
